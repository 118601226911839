/**
 * DeveloperCard.tsx
 *
 * A component for displaying an individual member.
 *
 */

import React, { forwardRef, useLayoutEffect, useRef, useState } from "react";
import "./Developers.scss";
import "./DeveloperCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

interface DeveloperCardProps {
  display?: boolean;
  developer: {
    firstName: string;
    lastName: string;
    linkedIn: string;
    batch: number;
    specializations: string[];
    imgName: string;
    execTeam: string;
  } | null;
  isExec: boolean;
  hexagonDOMRect: DOMRect | undefined;
  setDisplayDeveloperCard: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line react/display-name
export const DeveloperCard = forwardRef<HTMLDivElement, DeveloperCardProps>(
  (
    {
      display = true,
      developer = null,
      isExec,
      hexagonDOMRect,
      setDisplayDeveloperCard,
    },
    ref
  ) => {
    const developer_card_ref = useRef<HTMLDivElement>(null);
    const [cardPosition, setCardPosition] = useState({
      top: "0px",
      left: "0px",
    });
    const [cardRect, setCardRect] = useState<DOMRect | null>(null);
    // The specializations section works by translating a clipped div.
    // This reflects the "scroll state" of the specializations.
    const [translation, setTranslation] = useState(0);
    const [
      isRightSpecializationArrowVisible,
      setIsRightSpecializationArrowVisible,
    ] = useState(false);
    const inner_specializations_ref = useRef<HTMLDivElement>(null);
    const outer_specializations_ref = useRef<HTMLDivElement>(null);

    // Specialization area arrow click handlers
    const moveLeftClickHandler = () => {
      setTranslation(Math.min(translation + 100, 0));
    };

    const moveRightClickHandler = () => {
      if (
        inner_specializations_ref.current &&
        outer_specializations_ref.current
      ) {
        // Math magic courtesy of Heinrich; determines the maximum bound
        // of the scrolling of the specializations.
        const outer_minus_inner =
          outer_specializations_ref.current.getBoundingClientRect().width -
          inner_specializations_ref.current.getBoundingClientRect().width;
        const max_scroll = Math.max(
          translation - 100,
          outer_minus_inner > 0 ? 0 : outer_minus_inner
        );
        setTranslation(max_scroll);
      }
    };

    // Create a resize observer for the card.
    // We update the DOMRect (getBoundingClientRect()) when we detect a resize in the card.
    useLayoutEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setCardRect(entry.target.getBoundingClientRect());
        }
      });
      if (developer_card_ref.current) {
        resizeObserver.observe(developer_card_ref.current);
      }
      return () => {
        resizeObserver.disconnect();
      };
    }, []);

    // Determines whether the right specialization arrow is visible.
    useLayoutEffect(() => {
      if (
        outer_specializations_ref.current &&
        inner_specializations_ref.current
      ) {
        const outer_width = outer_specializations_ref.current.getBoundingClientRect()
          .width;
        const inner_width = inner_specializations_ref.current.getBoundingClientRect()
          .width;
        if (
          translation == outer_width - inner_width ||
          outer_width - inner_width > 0
        ) {
          setIsRightSpecializationArrowVisible(false);
          return;
        }
      }
      setIsRightSpecializationArrowVisible(true);
    }, [developer, translation]);

    useLayoutEffect(() => {
      if (hexagonDOMRect && cardRect && developer) {
        setTranslation(0);
        // Default position of cards.
        // Places the card at the right of the hexagon.
        let top =
          hexagonDOMRect.y +
          hexagonDOMRect.height / 2 -
          cardRect.height / 2 +
          window.pageYOffset;
        let left =
          hexagonDOMRect.x + hexagonDOMRect.width + window.pageXOffset + 25;

        if (window.innerWidth > 768) {
          // Position the card at the left of the hexagon is space is unavailable at the right.
          if (
            left + cardRect.width + 25 >=
            window.innerWidth + window.pageXOffset - 25
          ) {
            left = hexagonDOMRect.left - cardRect.width - 25;
          }

          // Position the card at the top of the hexagon if space is unavailable at the bottom.
          if (
            top + cardRect.height >=
            window.innerHeight + window.pageYOffset - 84
          ) {
            top = hexagonDOMRect.y + window.pageYOffset - cardRect.height - 25;
            left =
              hexagonDOMRect.x +
              hexagonDOMRect.width / 2 -
              cardRect.width / 2 +
              window.pageXOffset;
          }

          // Place the card at the bottom of the hexagon is space is unavailable at the top and right.
          if (top < window.pageYOffset) {
            top =
              hexagonDOMRect.y +
              hexagonDOMRect.height +
              window.pageYOffset +
              25;
            left =
              hexagonDOMRect.x +
              hexagonDOMRect.width / 2 -
              cardRect.width / 2 +
              window.pageXOffset;
          }
        } else {
          // If the viewport is small, position the card at the center.
          left = (window.innerWidth - cardRect.width) / 2;
          top = (window.innerHeight - cardRect.height) / 2 + window.pageYOffset;
        }
        setCardPosition((prevState) => {
          return {
            ...prevState,
            top: `${top}px`,
            left: `${left}px`,
          };
        });
      }
    }, [cardRect, hexagonDOMRect]);

    return (
      <div
        className={`${isExec ? "executive-card" : "developer-card"} ${
          display ? "developer-card-displayed" : ""
        }`}
        style={{
          ...cardPosition,
          pointerEvents: display ? "auto" : "none",
        }}
        ref={developer_card_ref}
        role="tooltip"
      >
        <div>{/*<Img fluid={developer.image} className="hex-image" /> */}</div>
        <div className="developer-card-contents" ref={ref}>
          <button
            className="card-close"
            onClick={() => setDisplayDeveloperCard(false)}
          >
            <FontAwesomeIcon size="1x" icon={faTimes} />
          </button>
          <h4 className="developer-card-text developer-card-name">
            {`${developer && developer.firstName} ${
              developer && developer.lastName
            }`}
          </h4>
          {isExec && (
            <h4 className="developer-card-text">{`${
              developer && developer.execTeam
            }`}</h4>
          )}
          <div className="developer-card-section">
            <h4 className="developer-card-text">{`Batch ${
              developer && developer.batch
            }`}</h4>
          </div>
          {developer && developer.specializations?.length > 0 && (
            <div className="developer-card-section">
              <h4 className="developer-card-text developer-card-section-title">
                Specializations
              </h4>
              <div
                className="outer-specializations-container"
                ref={outer_specializations_ref}
              >
                <button
                  className={`specialization-navigation ${
                    translation == 0
                      ? "specialization-navigation-button-hidden"
                      : ""
                  }`}
                  onClick={moveLeftClickHandler}
                >
                  <FontAwesomeIcon size="1x" icon={faChevronLeft} />
                </button>
                <button
                  className={`specialization-navigation ${
                    !isRightSpecializationArrowVisible
                      ? "specialization-navigation-button-hidden"
                      : ""
                  }`}
                  onClick={moveRightClickHandler}
                >
                  <FontAwesomeIcon size="1x" icon={faChevronRight} />
                </button>
                <div
                  className="developer-specializations"
                  style={{ transform: `translate(${translation}px, 0px)` }}
                  ref={inner_specializations_ref}
                >
                  {developer &&
                    developer.specializations.length > 0 &&
                    developer.specializations.map((value, index) => (
                      <div
                        key={`${value + index}`}
                        className="developer-specialization"
                      >
                        <span>{value}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          <div className="developer-card-section">
            <h4 className="developer-card-text developer-card-section-title">
              LinkedIn Profile
            </h4>
            <a
              className="social-media-link"
              href={`${developer ? developer.linkedIn : ""}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon size="2x" icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>
    );
  }
);
