import React from "react";
import { Developers } from "../components/Developers/Developers";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";

const DevelopersPage: React.FC = () => {
  return (
    <Layout hasFooter={false}>
      <SEO title="Developers - ACSS UPLB" />
      <Developers />
    </Layout>
  );
};

export default DevelopersPage;
