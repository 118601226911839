/**
 * Hexagon.tsx
 *
 * A component for displaying an individual member.
 *
 */

import React, { useRef } from "react";
import "./Developers.scss";
import "./Hexagon.scss";

interface HexagonProps {
  isFiller?: boolean;
  isExec?: boolean;
  infoIndex: number;
  setCurrentlySelectedDeveloper: React.Dispatch<
    React.SetStateAction<{
      isExec: boolean;
      index: number;
    }>
  >;
  setHexagonDOMRect: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setDisplayDeveloperCard: React.Dispatch<React.SetStateAction<boolean>>;
  hasAlreadyClickedAHexagon: boolean;
  setHasAlreadyClickedAHexagon: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Hexagon: React.FC<HexagonProps> = ({
  children,
  isFiller = false,
  isExec = false,
  infoIndex,
  setCurrentlySelectedDeveloper,
  setHexagonDOMRect,
  setDisplayDeveloperCard,
  hasAlreadyClickedAHexagon,
  setHasAlreadyClickedAHexagon,
}) => {
  const hexagon_ref = useRef<HTMLDivElement>(null);

  const clickHandler = (e: React.MouseEvent) => {
    setHasAlreadyClickedAHexagon(true);
    setHexagonDOMRect(e.currentTarget.getBoundingClientRect());
    setCurrentlySelectedDeveloper((previous) => {
      if (previous.index == infoIndex && previous.isExec == isExec) {
        setDisplayDeveloperCard((prev) => !prev);
      } else {
        setDisplayDeveloperCard(true);
      }
      return {
        isExec: isExec,
        index: infoIndex,
      };
    });
    return;
  };

  return (
    <li
      className={`hex-grid__item${isExec ? "-exec" : ""} ${
        isFiller ? "filler" : ""
      }
      ${
        isExec && infoIndex == 4 && !hasAlreadyClickedAHexagon
          ? "ehd-click-invitation"
          : ""
      }`}
    >
      <div
        className={`hex-grid__${isFiller ? "filler" : "content"}`}
        ref={hexagon_ref}
        onClickCapture={(e: React.MouseEvent) => clickHandler(e)}
      >
        {children}
      </div>
    </li>
  );
};
