import React, { useLayoutEffect, useRef, useState } from "react";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Hexagon } from "./Hexagon";
import developers from "./developers_list.json";

import "./Developers.scss";
import "../../styles/layout.scss";
import { DeveloperCard } from "./DeveloperCard";
import { useOutsideClickMultiple } from "../../hooks/useOutsideClick";
import _ from "lodash";

type Executive = {
  firstName: string;
  lastName: string;
  linkedIn: string;
  batch: number;
  specializations: string[];
  imgName: string;
  execTeam: string;
  image?: any;
} | null;

type Developer = {
  firstName: string;
  lastName: string;
  linkedIn: string;
  batch: number;
  specializations: string[];
  imgName: string;
  execTeam: string;
  image?: any;
};

export const Developers: React.FC = () => {
  // Image queries for the developers.
  // Numbering is aligned with arrangement in the JSON file.
  const data = useStaticQuery(graphql`
    query {
      images: allImageSharp(
        filter: {
          fluid: {
            originalName: {
              in: [
                "01_JohnKarlTumpalan.jpg"
                "02_DanAnthonyCalixto.jpg"
                "03_AbigailNadua.jpg"
                "04_RitaIsabelColina.jpg"
                "05_BienChristianCustodio.jpg"
                "06_RyanAndreiCruz.jpg"
                "07_JoaymaMustapha.jpg"
                "08_ElaineKilala.jpg"
                "09_DanielWilliamBorromeo.jpg"
                "10_RyanNathanielResoles.jpg"
                "11_MarkNestorArenillo.jpg"
                "12_JoseMariaIuchananIngan.jpg"
                "13_JonahleeXyrkcyszFabula.jpg"
                "14_RielJeffersonMorales.jpg"
                "15_JezreelMaeEstares.jpg"
                "16_DeniseReignMutia.jpg"
                "17_JohannCortez.jpg"
                "18_ElcidCruzado.jpg"
                "19_ShaneAngelaPalicpic.jpg"
                "20_JohnHeinrichAustria.jpg"
                "21_RessaMaeBongalon.jpg"
                "22_JohnAlwinPamintuan.jpg"
                "23_EthanPaguila.jpg"
                "24_ReneJothamCulaway.jpg"
                "25_JerichoCalibuso.jpg"
                "26_AlesundreauDaleRatuiste.jpg"
                "27_QuinGraceSabado.jpg"
                "28_LouiseGabrielleTalip.jpg"
              ]
            }
          }
        }
        sort: { fields: fluid___originalName }
      ) {
        nodes {
          fluid(quality: 100, maxWidth: 250, traceSVG: { color: "#1480de" }) {
            ...GatsbyImageSharpFluid_tracedSVG
            originalName
          }
        }
      }
    }
  `);

  // Attach the images to their corresponding devs
  data.images.nodes.forEach((node: any) => {
    developers.map((dev: Developer) => {
      if (node.fluid.originalName === dev.imgName) {
        dev.image = node.fluid;
      }
    });
  });

  // Set up the hexagon positions of the execs
  const execs = [
    developers[2], // acad
    developers[0], // docu
    developers[4], // fin
    developers[3], // log
    developers[22], // ehd (will be at the center)
    developers[17], // mem
    null, // filler item
    developers[21], // pub
    null, // filler item
  ];

  const exec_ulist_ref = useRef<HTMLUListElement>(null);
  const devs_ulist_ref = useRef<HTMLUListElement>(null);
  // Keeps state of scroll position.
  const [scrollPosition, setScrollPosition] = useState(0);
  // Keeps state of window size.
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  // Keeps state of whether the card is visible/invisible.
  const [displayDeveloperCard, setDisplayDeveloperCard] = useState(false);
  const [hasAlreadyClickedAHexagon, setHasAlreadyClickedAHexagon] = useState(
    false
  );
  // Refs for developer sections
  const executives_section_ref = useRef<HTMLElement>(null);
  const developers_section_ref = useRef<HTMLElement>(null);
  const developer_card_ref = useRef<HTMLDivElement>(null);

  //
  const [currentlySelectedDeveloper, setCurrentlySelectedDeveloper] = useState({
    isExec: false,
    index: 0,
  });
  const [developerInfo, setDeveloperInfo] = useState({} as Executive);
  const [hexagonDOMRect, setHexagonDOMRect] = useState<DOMRect>();

  useOutsideClickMultiple(
    [exec_ulist_ref, devs_ulist_ref, developer_card_ref],
    () => {
      setDisplayDeveloperCard(false);
    }
  );
  const updateScroll = () => {
    requestAnimationFrame(() => {
      setScrollPosition(window.pageYOffset);
    });
  };

  const updateResize = () => {
    requestAnimationFrame(() => {
      setWindowSize((prevState) => {
        return {
          ...prevState,
          width: window.innerWidth,
          height: typeof window !== "undefined" ? window.innerHeight : 0,
        };
      });
    });
  };

  // Enables a scroll listener.
  useLayoutEffect(() => {
    window.addEventListener("scroll", updateScroll, { passive: true });
    window.addEventListener("resize", updateResize, { passive: true });
    return () => {
      window.removeEventListener("scroll", updateScroll);
      window.removeEventListener("resize", updateResize);
    };
  }, []);

  useLayoutEffect(() => {
    if (currentlySelectedDeveloper.isExec) {
      if (execs[currentlySelectedDeveloper.index]) {
        setDeveloperInfo(execs[currentlySelectedDeveloper.index]);
      }
    } else {
      setDeveloperInfo(developers[currentlySelectedDeveloper.index]);
    }
  }, [currentlySelectedDeveloper]);

  useLayoutEffect(() => {
    setDisplayDeveloperCard(false);
  }, [scrollPosition, windowSize]);

  const executiveAnimationProgress = `${
    typeof window != "undefined" && window.innerWidth >= 768
      ? (
          Math.min(
            1,
            scrollPosition /
              (executives_section_ref?.current
                ? executives_section_ref.current.offsetHeight
                : 0 - (typeof window !== "undefined" ? window.innerHeight : 1))
          ) * -1
        ).toFixed(2)
      : 0
  }s`;

  const developerAnimationProgress = `${
    typeof window !== "undefined" && window.innerWidth >= 768
      ? Math.min(
          Math.max(
            -1,
            (scrollPosition /
              (developers_section_ref?.current
                ? developers_section_ref.current.offsetHeight
                : 0 -
                  (typeof window !== "undefined" ? window.innerHeight : 1))) *
              -1 +
              1
          ),
          0
        ).toFixed(2)
      : 0
  }s`;

  return (
    <main className="page-layout developers-page">
      <div
        className="devs-scroll-indicator"
        style={{
          opacity: `${
            typeof window !== "undefined" && scrollPosition > 250 ? 0 : 1
          }`,
        }}
      >
        {/* Arrow Down Icon */}
        <svg
          className="arrow-down-icon"
          width="28"
          height="6"
          viewBox="0 0 28 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="1"
            y1="-1"
            x2="13.5602"
            y2="-1"
            transform="matrix(0.961524 0.274721 -0.420009 0.90752 0 2)"
            stroke="#C9C9C9"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <line
            x1="1"
            y1="-1"
            x2="13.5602"
            y2="-1"
            transform="matrix(-0.961524 0.274721 0.420009 0.90752 28 2)"
            stroke="#C9C9C9"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="developers-sticky-container">
        <DeveloperCard
          ref={developer_card_ref}
          display={displayDeveloperCard}
          developer={developerInfo}
          isExec={currentlySelectedDeveloper.isExec}
          hexagonDOMRect={hexagonDOMRect}
          setDisplayDeveloperCard={setDisplayDeveloperCard}
        />
        <div
          className="hex-grids"
          style={{
            animationDelay: `calc(${
              scrollPosition /
              (executives_section_ref?.current
                ? executives_section_ref.current.offsetHeight
                : 0 - (typeof window !== "undefined" ? window.innerHeight : 1))
            } * -1s)`,
          }}
        >
          <div className="hex-grid">
            <section
              className="executives-section"
              ref={executives_section_ref}
              style={{
                pointerEvents:
                  typeof window !== "undefined" &&
                  (scrollPosition /
                    (executives_section_ref?.current
                      ? executives_section_ref.current.offsetHeight
                      : 0 - window.innerHeight)) *
                    -1 >
                    -1
                    ? "all"
                    : "none",
              }}
            >
              <header
                className="executives-title"
                style={{
                  animationDelay: executiveAnimationProgress,
                }}
              >
                <h1 className="meet-our">
                  Meet our{" "}
                  <span className="developer-team-name">Executive Team</span>
                </h1>
              </header>
              <ul
                className="hex-grid__list-exec"
                style={{
                  animationDelay: executiveAnimationProgress,
                }}
                ref={exec_ulist_ref}
              >
                {execs.map((exec: Executive, i) => (
                  <Hexagon
                    key={i}
                    isFiller={_.isNull(exec)}
                    isExec
                    infoIndex={i}
                    setCurrentlySelectedDeveloper={
                      setCurrentlySelectedDeveloper
                    }
                    setHexagonDOMRect={setHexagonDOMRect}
                    setDisplayDeveloperCard={setDisplayDeveloperCard}
                    hasAlreadyClickedAHexagon={
                      i == 4 ? hasAlreadyClickedAHexagon : true
                    }
                    setHasAlreadyClickedAHexagon={setHasAlreadyClickedAHexagon}
                  >
                    <Img
                      fluid={exec?.image}
                      className="hex-image"
                      style={{ pointerEvents: "none" }}
                    />
                  </Hexagon>
                ))}
              </ul>
            </section>
          </div>
          <div className="hex-grid">
            <section
              className="developers-section"
              ref={developers_section_ref}
              style={{
                animationDelay: developerAnimationProgress,
              }}
            >
              <header
                className="developers-title"
                style={{
                  animationDelay: developerAnimationProgress,
                }}
              >
                <h1 className="meet-our">
                  Meet our{" "}
                  <span className="developer-team-name">Developers</span>
                </h1>
              </header>
              <ul
                className="hex-grid__list"
                style={{
                  animationDelay: developerAnimationProgress,
                }}
                ref={devs_ulist_ref}
              >
                {developers.map((dev: Developer, i) => (
                  <Hexagon
                    key={i}
                    isExec={false}
                    infoIndex={i}
                    setCurrentlySelectedDeveloper={
                      setCurrentlySelectedDeveloper
                    }
                    setHexagonDOMRect={setHexagonDOMRect}
                    setDisplayDeveloperCard={setDisplayDeveloperCard}
                    hasAlreadyClickedAHexagon={false}
                    setHasAlreadyClickedAHexagon={setHasAlreadyClickedAHexagon}
                  >
                    <Img
                      fluid={dev.image}
                      className="hex-image"
                      style={{ pointerEvents: "none" }}
                    />
                  </Hexagon>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};
